import create from 'zustand';
import * as Sentry from '@sentry/browser';
import { devtools, persist } from 'zustand/middleware';
import { API } from 'utils/api';
import { login, tmoreLogin } from 'api/session';
import { setSentryUser } from 'error-tracking';

const useStore = create(
	devtools(
		persist(
			(set, get) => ({
				user: null,
				tmToken: null,
				classRoomId: null,
				lessonId: null,
				source: null,
				tmoreToken: null,
				os: null,
				appVersion: null,
				appId: null,
				isTuitionAppUser: false,

				initializeSession: async (query) => {
					const newTmToken = query.get('token');
					const classRoomId = query.get('class_id');
					const lessonId = query.get('lesson_id');
					const source = query.get('source');
					const os = query.get('os');
					const isTuitionAppUser = query.get('is_tuition_app') === 'true';
					const appVersion =
						query.get('app-version') || query.get('app_version');
					const appId = query.get('app_id');
					const {
						tmToken,
						user,
						classRoomId: classRoomIdOld,
						lessonId: lessonIdOld,
					} = get();

					if (classRoomId)
						API.defaults.headers.common['tm-classroom-id'] = classRoomId;

					if (lessonId) API.defaults.headers.common['tm-lesson-id'] = lessonId;
					set({ isTuitionAppUser });

					// IF token changed, relogin user
					if (newTmToken && newTmToken !== tmToken) {
						const resData = await login({ token: newTmToken });
						set({
							user: resData.user,
							tmToken: newTmToken,
							classRoomId,
							source,
							os,
							appVersion,
							lessonId,
							appId,
						});

						sessionStorage &&
							sessionStorage.setItem('userUuid', resData.user.userUuid);

						API.defaults.headers.common['tm-user-uuid'] = resData.user.userUuid;

						if (process.env.REACT_APP_ENV === 'development') {
							sessionStorage &&
								sessionStorage.setItem('token', resData.user.token);
							API.defaults.headers.common[
								'Authorization'
							] = `Bearer ${resData.user.token}`;
						}

						setSentryUser(resData.user._id);

						return { user: resData.user, classRoomId, lessonId };
					}

					API.defaults.headers.common['tm-user-uuid'] =
						(sessionStorage && sessionStorage.getItem('userUuid')) ||
						user.userUuid;

					API.defaults.headers.common['Authorization'] = `Bearer ${
						(sessionStorage && sessionStorage.getItem('token')) || user.token
					}`;

					setSentryUser(user._id);

					return {
						user,
						classRoomId: classRoomIdOld,
						source,
						lessonId: lessonIdOld,
					};
				},

				setClassRoomHeader: (query, clsId) => {
					let classRoomId = clsId || query.get('class_id');
					if (!classRoomId) {
						({ classRoomId } = get());
					}
					if (classRoomId)
						API.defaults.headers.common['tm-classroom-id'] = classRoomId;

					let lessonId = query.get('lesson_id');
					if (!lessonId) {
						({ lessonId } = get());
					}
					if (lessonId) API.defaults.headers.common['tm-lesson-id'] = lessonId;
				},
				setUser: (user) => {
					set({ user });
				},
				initTmoreSession: async (userId, role) => {
					const data = await tmoreLogin({ userId, role });
					set({ user: data.user, tmoreToken: data.token, source: 'web' });
				},
			}),
			{ name: 'user' },
		),
		{ name: 'user' },
	),
);

export default useStore;
